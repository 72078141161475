<template>
  <div class="videoDetail">
    <div class="picMsg onlyBorderBottomForm" v-loading="loading">
      <el-form
        :label-position="'left'"
        label-width="130px"
        :model="from"
        :rules="rules"
        ref="ruleForm"
        :hide-required-asterisk="true"
      >
        <el-form-item :label="$t('cover') + $t('colon')">
          <el-image
            style="width: 300px; height: 170px; background: #f2f2f2"
            v-if="detail.cover_url"
            :src="detail.cover_url"
            :preview-src-list="[detail.cover_url]"
            :fit="'contain'"
          ></el-image>
        </el-form-item>
        <el-form-item>
          <div class="coverList" v-if="!isExamine">
            <p class="topTips">{{ $t("VideoCoverTipsTop") }}</p>
            <div
              class="imgList clearfix"
              style="margin-top: 10px"
              v-if="detail.screenshot_url.length"
            >
              <div
                class="imgItem fl"
                :class="{ iscover: item == detail.cover_url }"
                v-for="(item, index) in detail.screenshot_url"
                :key="index"
              >
                <el-image
                  style="width: 105px; height: 66px"
                  :src="item"
                  :fit="'cover'"
                ></el-image>
                <div class="iconsBox">
                  <i class="el-icon-zoom-in" @click="showImg(item)"></i>
                  <i class="el-icon-circle-check" @click="setCover(item)"></i>
                  <!-- <i class="el-icon-delete" @click="deleteCocer(index)"></i> -->
                </div>
                <i
                  v-if="item == detail.cover_url"
                  class="isCoverIcon el-icon-check"
                ></i>
              </div>
            </div>
            <div
              class="noMsg"
              v-else
              style="
                margin-bottom: 35px;
                text-align: center;
                font-size: 14px;
                color: #999;
                margin-top: 20px;
              "
            >
              {{ $t("noImage") }}
            </div>
            <p class="bottomTips">
              {{ $t("VideoCoverTipsBot") }}
            </p>
            <fileUpload
              @uploadCompleteOSS="uploadCompleteOSS"
              :needCropImg="true"
              ref="uploadComponents"
            >
              <el-button type="text" icon="el-icon-plus">{{
                $t("uploadCover")
              }}</el-button>
            </fileUpload>
          </div>
        </el-form-item>
        <el-form-item :label="$t('designation') + $t('colon')" prop="name">
          <span v-if="isExamine">{{ detail.name }}</span>
          <el-input
            v-else
            style="width: 300px"
            v-model="from.name"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('ID') + $t('colon')">{{
          detail.aliyun_id
        }}</el-form-item>
        <el-form-item :label="$t('duration') + $t('colon')">{{
          detail.duration ? detail.duration : 0 | formatSeconds
        }}</el-form-item>
        <el-form-item :label="$t('size') + $t('colon')">{{
          detail.size | getFilesSize
        }}</el-form-item>
        <el-form-item :label="$t('createTime') + $t('colon')">{{
          detail.created_time | secondFormat
        }}</el-form-item>
        <el-form-item :label="$t('changeTime') + $t('colon')">{{
          detail.updated_time | secondFormat
        }}</el-form-item>
      </el-form>
    </div>
    <el-dialog
      :visible.sync="coverItemDialog"
      append-to-body
      close-on-click-modal
    >
      <img width="100%" :src="dialogImageUrl" alt />
    </el-dialog>
    <div class="modifyBtn">
      <el-button
        type="primary"
        size="small"
        @click="modify"
        v-if="isExamine && inspectButton('coi:ved:mdi')"
        >{{ $t("modify") }}</el-button
      >
      <el-button
        type="primary"
        size="small"
        @click="saveInfo"
        :loading="saveLoading"
        v-if="!isExamine"
        >{{ $t("save") }}</el-button
      >
      <el-button
        type="default"
        size="small"
        @click="cancelModify"
        v-if="!isExamine"
        >{{ $t("cancel") }}</el-button
      >
    </div>
  </div>
</template>
<script>
import fileUpload from "~/baseComponents/fileUpload";
export default {
  components: { fileUpload },
  props: {
    video_id: {
      type: Number || String,
      default: null,
    },
  },
  watch: {
    video_id() {
      this.isExamine = true;
      this.getDetail();
    },
  },
  data() {
    return {
      loading: false,
      uploadName: "",
      coverItemDialog: false,
      dialogImageUrl: "",
      isExamine: true,
      saveLoading: false,
      detail: {},
      from: {
        name: "",
      },

      rules: {
        name: [
          {
            required: true,
            message: " ",
            trigger: "blur",
          },
          {
            min: 3,
            max: 50,
            message: this.$t("pictureSetTipslength"),
            trigger: "blur",
          },
        ],
      },
      coverImg: "",
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    async getDetail(id) {
      this.loading = true;
      try {
        let params = {
          video_id: this.video_id,
        };
        let data = await this.$store.dispatch(
          "baseConsole/company_getVideoDetail",
          params
        );
        if (data.success) {
          this.detail = data.data;
          this.coverImg = data.data.cover_url;
          this.loading = false;
        }
      } catch (e) {}
    },
    uploadCompleteOSS(data) {
      this.detail.cover_url = data.url;
      this.$refs.uploadComponents.deleteOssItem(this.uploadName);
      this.uploadName = data.name;
      //   this.$log({
      //     type: 1,
      //     topic: "console.company",
      //     id: "console.company.video.cover",
      //     content: JSON.stringify({
      //       video_cover: data.url,
      //     }),
      //   });
    },
    showImg(url) {
      this.dialogImageUrl = url;
      this.coverItemDialog = true;
    },
    modify() {
      this.from.name = this.detail.name;
      this.isExamine = false;
    },
    cancelModify() {
      this.isExamine = true;
      this.from.name = "";
      //   this.$refs["ruleForm"].clearValidate();
    },
    setCover(url) {
      this.detail.cover_url = url;
    },
    saveInfo() {
      this.$refs["ruleForm"].validate(async (valid) => {
        if (valid) {
          this.saveLoading = true;
          try {
            let params = {
              video_id: this.detail.id,
              name: this.from.name,
              cover_url: this.detail.cover_url,
            };
            let data = await this.$store.dispatch(
              "baseConsole/company_changeVideoMsg",
              params
            );
            if (data.success) {
              this.detail.name = this.from.name;
              this.saveLoading = false;
              this.isExamine = true;
              this.$emit("successChange");
              this.$ssTip();
            }
          } catch {
            this.saveLoading = false;
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.videoDetail {
  padding: 0 20px 20px;
  height: calc(100vh - 50px);
  .title {
    line-height: 42px;
    font-size: 14px;
    font-weight: 900;
  }
  .textLink {
    float: right;
    line-height: 42px;
    font-size: 12px;
    color: #858585;
    cursor: pointer;
  }
  .picMsg {
    margin-top: 20px;
    max-height: 600px;
    overflow-y: auto;
  }
  .el-form-item {
    margin-bottom: 15px;
  }
  .coverList {
    width: 380px;
    min-height: 150px;
    margin-top: -10px;
    .topTips,
    .bottomTips {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 22px;
    }
  }
  .imgItem {
    position: relative;
    width: 105px;
    height: 66px;
    margin-right: 15px;
    margin-bottom: 15px;
    overflow: hidden;
    .iconsBox {
      visibility: hidden;
      opacity: 0;
      width: 105px;
      height: 66px;
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
      font-size: 18px;
      line-height: 66px;
      text-align: center;
      letter-spacing: 4px;
      transition: all 0.3s;
      i {
        cursor: pointer;
      }
    }
    .isCoverIcon {
      display: block;
      width: 15px;
      height: 15px;
      line-height: 15px;
      text-align: center;
      font-size: 12px;
      font-weight: 900;
      color: #fff;
      background: #409eff;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .iscover {
    border: 1px solid #409eff;
  }
  .imgItem:nth-child(4n) {
    margin-right: 0;
  }
  .imgItem:hover .iconsBox {
    opacity: 1;
    visibility: visible;
  }
}
.modifyBtn {
  padding-top: 10px;
  position: absolute;
  bottom: 20px;
}
</style>